import React from "react"

import Seo from "components/Seo"
import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import BanerContact from "components/BanerContact"

import { Content } from "page_components/config-sample"

import ConfigProvider from "context/config"
import { useProducts } from "context/products"

const ConfigSamples = ({ location }) => {
  const { products } = useProducts()

  const formatted_products = products?.filter(item =>
    item?.productCategories?.nodes?.some(item => item?.slug === "tapety")
  )

  return (
    <ConfigProvider location={location} products={formatted_products}>
      <Content />
    </ConfigProvider>
  )
}

const Samples = ({ location }) => {
  return (
    <Layout location={location}>
      <Breadcrumbs title="Próbki" />
      <ConfigSamples location={location} />
      <BanerContact />
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="Spersonalizuj Zamówienie - Odmień Swoje Wnętrze"
    description="Stworzone przez nas ekskluzywne tapety dekoracyjne zachwycają swoim designem. Spersonalizuj swoje zamówienie na wallcraft.pl i ciesz się nowoczesnym wnętrzem."
  />
)

export default Samples
